<template>
    <div id="SeminarForm" class="w60 serif">
        <ElForm
            :label-position="store.getters.labelPosition"
            label-width="250px"
            ref="seminarFormRef"
            :model="seminarFormData"
            :rules="seminarFormRules"
        >
            <h2 class="f29 f22-mb">
                大自然界 基本セミナー<br />
                お申し込みフォーム
            </h2>
            <p class="f14 f12-mb">
                「大自然界 基本セミナー」への参加をご希望される方は、<br />
                下記フォームに必要事項をご記入の上、お申し込みください。
            </p>
            <p class="f14 f12-mb">
                申し込み完了後、大自然界より確認メールを送らせて頂いております。<br />
                確認メールが届かない場合は、お手数ですが、「お問い合わせフォーム」よりご連絡ください。
            </p>
            <ElFormItem class="f14 f14-mb" label="お名前" prop="name">
                <ElInput v-model="seminarFormData.name"></ElInput>
            </ElFormItem>
            <ElFormItem class="f14 f14-mb" label="メールアドレス" prop="email">
                <ElInput v-model="seminarFormData.email"></ElInput>
            </ElFormItem>
            <ElFormItem
                class="f14 f14-mb"
                label="メールアドレス（確認用）"
                prop="emailCheck"
            >
                <ElInput v-model="seminarFormData.emailCheck"></ElInput>
            </ElFormItem>
            <button
                class="f29 f22-mb"
                @click.prevent="submitForm(seminarFormRef)"
            >
                送 信
            </button>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'SeminarForm',
}
</script>

<script setup>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { postSeminar } from '@/service/api'
import { getSeminarFormRules } from '@/utils/formRules'
const store = useStore()
const seminarFormRef = ref(null)
const seminarFormData = reactive({
    name: '',
    email: '',
    emailCheck: '',
})
const seminarFormRules = getSeminarFormRules(seminarFormData)
const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {
        if (valid) {
            let formData = {
                name: seminarFormData.name,
                email: seminarFormData.email,
            }
            postSeminar(formData).then(() => {
                ElMessage({
                    message: 'ご記入いただいた情報は無事送信されました。',
                    type: 'success',
                    offset: 100,
                })
                setTimeout(() => {
                    seminarFormData.name = ''
                    seminarFormData.email = ''
                    seminarFormData.emailCheck = ''
                    location.reload()
                }, 2000)
            })
        } else {
            return false
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';

#SeminarForm {
    text-align: center;
    box-sizing: border-box;
    background-color: $white;
    border: 10px solid rgba($majorPurple, 0.3);

    h2 {
        @include h1Style($majorPurple, 5px);
    }
    p {
        margin: 30px 0;
        color: $gray6;
    }
    button {
        @include solidButtonStyle();
        margin: 50px auto 0;
        width: 200px;
    }

    @media screen and (min-width: 768px) {
        padding: 30px 80px 50px;
    }
    @media screen and (max-width: 767px) {
        padding: 40px;
    }
}
</style>
