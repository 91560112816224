<template>
    <div id="DonateProcessStripe">
        <!-- 填寫捐款金額 -->
        <section id="step1" v-if="stepController === 1">
            <article class="f14 f12-mb">
                <p>
                    「貴き親理のお授け」を
                    <br />真拝命拝霊拝受させて頂くにあたり、
                    <br />『大親神大生命』に対し奉り
                    <br />「おつなぎ徳積みの理」を行わせて頂きましょう。
                </p>
            </article>
            <div class="login-form-style">
                <ElForm
                    :label-position="store.getters.labelPosition"
                    label-width="250px"
                    ref="amountFormRef"
                    :model="amountFormData"
                    :rules="amountFormRules"
                >
                    <ElFormItem
                        class="f14 f14-mb"
                        label="金額（円）"
                        prop="amount"
                    >
                        <ElInputNumber
                            v-model="amountFormData.amount"
                            :step="1000"
                            :min="1000"
                        ></ElInputNumber>
                    </ElFormItem>
                    <ElFormItem
                        class="f14 f14-mb"
                        label="おつなぎ徳積みの方法"
                        prop="method"
                    >
                        <ElRadioGroup v-model="amountFormData.method">
                            <ElRadio :label="1">クレジットカード</ElRadio>
                            <ElRadio :label="2">銀行振込</ElRadio>
                        </ElRadioGroup>
                    </ElFormItem>
                    <div class="button-wrap">
                        <button
                            class="f18 f16-mb"
                            @click.prevent="submitAmountForm"
                        >
                            決定
                        </button>
                    </div>
                </ElForm>
            </div>
        </section>
        <!-- 確認是否捐款 -->
        <section id="step2" v-if="stepController === 2">
            <article>
                <h2 class="f24 f20-mb">
                    おつなぎ徳積み：<span class="bold">{{
                        amountFormData.amount
                    }}</span>
                    円
                </h2>
                <h2 class="f24 f20-mb">
                    方法：<span class="bold">{{
                        amountFormData.method == 1
                            ? 'クレジットカード'
                            : '銀行振込'
                    }}</span>
                </h2>
            </article>
            <article class="f14 f12-mb">
                <p>
                    『大親神大生命』にお受け取り頂くものですので、返金・キャンセルは出来かねます。
                    <br />
                    上記の内容で実行してよろしいですか?
                </p>
            </article>
            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="returnAmount">
                    いいえ
                </button>
                <button class="f18 f16-mb" @click.prevent="startPayment">
                    はい
                </button>
            </div>
        </section>
        <section id="step3" v-if="stepController === 3">
            <PaymentForm :amountFormData="amountFormData"></PaymentForm>
        </section>
    </div>
</template>
<script>
export default {
    name: 'DonateProcessStripe',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { getAmountFormRules } from '@/utils/formRules'
import { PaymentForm } from '@/components'
// const router = useRouter()
const store = useStore()
const stepController = ref(1)

// amountForm
const amountFormRef = ref(null)
const amountFormData = reactive({ amount: 1000, method: 1 })
const amountFormRules = getAmountFormRules()
function submitAmountForm() {
    amountFormRef.value.validate(async (valid) => {
        if (valid) {
            stepController.value += 1
        } else {
            return false
        }
    })
}

// startPayment or returnHome
function returnAmount() {
    stepController.value -= 1
}
function startPayment() {
    stepController.value += 1
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';

article {
    margin: 30px 0;
}
.button-wrap {
    display: flex;
    justify-content: center;
}
button {
    @include solidButtonStyle($majorPurple);
    margin: 50px 10px 0;
    width: 200px;
}
</style>
